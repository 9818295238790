<template>
  <div style="padding: 20px; width:40%">
    <el-form :model="form" label-width="120">
      <el-form-item label="用户名">
        <el-input v-model="form.username"></el-input>
      </el-form-item>
      <el-form-item label="密码">
        <el-input v-model="form.password" type="password"></el-input>
      </el-form-item>
      <el-form-item align="center">
        <el-button type="primary" @click="login" class="pull-right btn btn-large btn-primary">登录</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        username: '',
        password: ''
      },
      asideMenu: [
        {
          path: '/',
          name: 'home',
          label: '首页',
          icon: 's-home',
          url: 'Home/Home'
        },
        {
          path: '/contract_manage',
          name: 'contract_manage',
          label: '合同管理',
          icon: 'document',
          url: 'ContractManage/ContractManage'
        },
        {
          path: '/collection',
          name: 'collection',
          label: '回款明细管理',
          icon: 'files',
          url: 'CollectionManage/CollectionManage'
        },
        {
          path: '/financial_collection',
          name: 'financial_collection',
          label: '财务回款',
          icon: 'setting',
          url: 'FinancialCollection/FinancialManage'
        },
        {
          path: '/material_dispatch',
          name: 'material_dispatch',
          label: '物资调配',
          icon: 'setting',
          url: 'MaterialDispatch/MaterialDispatch'
        },
        {
          path: '/additional_contract',
          name: 'additional_contract',
          label: '合同外追加',
          icon: 'setting',
          url: 'AdditionalContract/AdditionalContractManage'
        }
      ]
    }
  },
  methods: {
    login() {
      this.$axios.post('/login/', this.form).then(res => {
        res = res.data
        console.log('response', res)
        if (res.code === 1000) {
          this.$store.commit('clearMenu')
          this.$store.commit('setMenu', this.asideMenu)
          this.$store.commit('setToken', res.token)
          this.$store.commit('setUserCookie', res.name)
          this.$store.commit('addMenu', this.$router)
          this.$router.push({ name: 'home' })
        } else {
          this.$message.warning(res.msg)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.el-form {
  width: 50%;
  margin: auto;
  padding: 45px;
  height: 450px;
  background-color: #fff;
}
</style>
//
<style></style>
